/* eslint-disable import/prefer-default-export */
import VueJwtDecode from 'vue-jwt-decode';
import moment from 'moment';

export const ifAuthenticated = (to, from, next) => { // eslint-disable-line
  const token = JSON.parse(localStorage.getItem('vuex')
  || '{}')?.user?.token;

  if (token) {
    const decoded = VueJwtDecode.decode(token.replace('Bearer ', ''));
    if (decoded.exp >= moment().format('X')) {
      next();
    } else {
      window.location.href = '/login?expired=true';
    }
  } else {
    window.location.href = '/login';
  }
};

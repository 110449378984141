<template>
  <section id="full-content">
    <div class="background-image">
      <video autoplay muted loop class ="fixed-video">
        <source src="./assets/images/flat_without_character_animation.mp4" type="video/mp4">
        Your browser does not support the video tag.
      </video>
    </div>
    <nav-bar></nav-bar>
    <div class="social-media">
      <div class="media" v-for="(media, index) in medias" :key="index">
        <a :href="media.link" target="_blank">
          <img :src="media.img" alt="media.name">
        </a>
      </div>
    </div>
    <div id="app">
      <img src="./assets/images/pagodera-logo-background.png" alt="under" class = "underlay"/>
      <img src="./assets/images/pagodera-logo.png" alt="Pagodera Logo" class = "overlay">
      <img src="./assets/images/boostedbosspedestal.png" alt="pedestalcreature"
      class = "pedestalcreature"/>
      <img src="./assets/images/boostedbosspedestal.png" alt="pedestalboss" class = "pedestalboss"/>
      <img :src="getCreatureImageSrc" alt="Creature Image" class="creature-image" />
      <img :src="getBossImageSrc" alt="Boss Image" class="boss-image" />
      <router-view/>
    </div>
    <md-snackbar
      :md-position="getSnackBar.position"
      :md-duration="getSnackBar.duration"
      :md-active.sync="getSnackBar.showSnackbar" md-persistent>
      <span>{{getSnackBar.message }}</span>
      <md-button class="md-primary" @click="dismiss()">{{ getSnackBar.button }}</md-button>
    </md-snackbar>
  </section>
</template>
<script>
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      medias: [
        {
          name: 'discord',
          link: 'https://discord.gg/C4RzBtC6cc',
          img: require('./assets/images/discord-logo-white.png'), // eslint-disable-line
        },
        {
          name: 'instagram',
          link: 'https://instagram.com/pagoderaot',
          img: require('./assets/images/instagram-logo-white.png'), // eslint-disable-line
        },
        {
          name: 'facebook',
          link: 'https://facebook.com/Pagoderaots/',
          img: require('./assets/images/facebook-logo-white.png'), // eslint-disable-line
        },
        {
          name: 'youtube',
          link: 'https://youtube.com/@PagoderaOficial',
          img: require('./assets/images/youtube-logo-white.png'), // eslint-disable-line
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['getSnackBar', 'getLastUpdate']),
    getCreatureImageSrc() {
      // Construct the image path for the creature
      if (this.getBoosted && this.getBoosted.creatureboostname) {
        const creatureNameWithUnderscores = this.getBoosted.creatureboostname.replace(/ /g, '_');
        return `./assets/images/creatures/${creatureNameWithUnderscores}.gif`;
      }
      return ''; // Return an empty string if no creature name is available
    },
    getBossImageSrc() {
      // Construct the image path for the creature
      if (this.getBoosted && this.getBoosted.bossboostname) {
        const bossNameWithUnderscores = this.getBoosted.bossboostname.replace(/ /g, '_');
        return `./assets/images/creatures/${bossNameWithUnderscores}.gif`;
      }
      return ''; // Return an empty string if no creature name is available
    },
  },
  mounted() {
    if (this.$route?.query?.expired) {
      this.setSnackBar({
        showSnackbar: true,
        position: 'center',
        duration: 4000,
        message: 'Sua sessão expirou, entre novamente.',
        button: 'Entendido',
      });
    }
  },
  methods: {
    ...mapActions(['setSnackBar', 'setLastUpdate']),
    dismiss() {
      this.setSnackBar({});
    },
    async getBoosted() {
      this.loadingBoosted = true;

      try {
        const result = await axios.get(`${process.env.VUE_APP_API}/boosted`, {
          headers: {
            Authorization: this.getUser.token,
          },
        });
        if (result.data.response) {
          result.data.response.bossboostname = result.data.response.bossboostname.replace(/ /g, '_');
          result.data.response.creatureboostname = result.data.response.creatureboostname.replace(/ /g, '_');
        }
        this.boosted = result.data.response;
        this.loadingBoosted = false;
      } catch (error) {
        this.loadingBoosted = false;
        this.setSnackBar({
          showSnackbar: true,
          position: 'center',
          duration: 4000,
          message: error?.response?.data?.message?.replace('Error:', '') || error?.response?.data,
          button: 'Entendido',
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import './assets/css/themes';

video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    background: none;
  }
.underlay {
  /* Set the dimensions of the underlay image */
  width: 400px; /* Adjust as needed */
  height: auto; /* Maintain aspect ratio */
}

.overlay {
  /* Set the dimensions of the overlay image */
  width: 400px; /* Adjust as needed */
  height: auto; /* Maintain aspect ratio */
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2; /* Ensure the overlay is above the underlay */
}

.pedestalcreature{
  position: absolute;
  right: 181px;
  top: 187px;
  width: 72px;
  height: 69px;
  z-index: -5;
}
.creature-image{
  position: absolute;
  right: 181px;
  top: 187px;
  width: 64px;
  height: 64px;
  z-index: -6;
}
.pedestalboss{
  position: absolute;
  right: 121px;
  top: 187px;
  height: 69px;
  width: 72px;
  z-index: -4;
}
.boss-image{
  position: absolute;
  right: 121px;
  top: 187px;
  width: 64px;
  height: 64px;
  z-index: -7;
}

#full-content {
  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@200;300;400;500;600;700;800&display=swap');
  h1, h2, h3, h4, p, span, b {
    font-family: 'Noto Sans', sans-serif !important;
  }
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: column;
  transition: .3s;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-color: transparent;
  background-position: top center;

  .background-image {
    position: absolute;
    z-index: -1;
    background: none;
    max-height: 702px;
  }
  .fixed-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

  .social-media {
    position: fixed;
    right: 0;
    bottom: 40%;
    background-color: rgba($color: #000000, $alpha: .5);
    padding: 20px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;

    .media {
      padding-top: 10px;
      padding-bottom: 10px;
      a {
        img {
          width: 40px;
        }
      }
    }
  }

  #app {
    width: 60%;
    display: flex;
    flex-direction: column;
    padding-bottom: 150px;

    @media (max-width: 1000px) {
      width: 100%;
      padding: 20px;
      padding-bottom: 150px;
    }

    img {
      margin: auto;
    }
  }
}
</style>
<style lang="scss">
body {
  :not(.focus):not(:hover)::-webkit-scrollbar-thumb {
    background-color: transparent!important;
  }
  ::-webkit-scrollbar {
    background-color: transparent;
    width:8px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-track:hover {
    background-color:transparent;
  }
  /* scrollbar itself */
  ::-webkit-scrollbar-thumb {
    background-color:#babac0;
    border-radius:16px;
    width: 3px!important;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color:#a0a0a5;
  }
  .scrollbar::-webkit-scrollbar-button {
    display:none
  }
}
</style>

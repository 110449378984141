import Vue from 'vue';
import VueMaterial from 'vue-material';
import moment from 'moment';
import Vuelidate from 'vuelidate';
import Paginate from 'vuejs-paginate';
import VueQuillEditor from 'vue-quill-editor';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import 'vue-material/dist/vue-material.min.css';
import 'vue-material/dist/theme/default.css';
import './assets/css/_themes.scss';
import './assets/js/components';
import i18n from './i18n';
// require styles
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

Vue.use(VueQuillEditor /* { default global options } */);
Vue.use(VueMaterial);
Vue.use(Vuelidate);
moment.locale('pt-br');
Vue.component('paginate', Paginate);
Vue.config.productionTip = false;
Vue.material.locale.dateFormat = 'dd/MM/yyyy';
Vue.prototype.moment = moment;

Vue.use(VueReCaptcha, { siteKey: '6LeOYewjAAAAADtPhyij88VD30iKQg6jYVpjWHRt' });

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

import Vue from 'vue';
import VueRouter from 'vue-router';
import { ifAuthenticated } from '@/assets/js/authentication';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Homepage',
    component: () => import('../views/Homepage.vue'),
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
  },
  {
    path: '/rankings',
    name: 'Rankings',
    component: () => import('../views/Rankings.vue'),
  },
  {
    path: '/guilds',
    name: 'Guilds',
    component: () => import('../views/Guilds.vue'),
  },
  {
    path: '/guild/:id',
    name: 'Guild',
    component: () => import('../views/Guild.vue'),
  },
  {
    path: '/download',
    name: 'Download',
    component: () => import('../views/Download.vue'),
  },
  {
    path: '/create-account',
    name: 'Create Account',
    component: () => import('../views/CreateAccount.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/account-confirmation/:email/:confirmationKey',
    name: 'Account Confirmation',
    component: () => import('../views/AccountConfirmation.vue'),
  },
  {
    path: '/news',
    name: 'News',
    redirect: { name: 'Homepage' },
  },
  {
    path: '/post/:id',
    name: 'Post',
    component: () => import('../views/Post.vue'),
  },
  {
    path: '/posts-archive',
    name: 'Archive',
    component: () => import('../views/Archive.vue'),
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    component: () => import('../views/ForgotPassword.vue'),
  },
  {
    path: '/reset-password/:token',
    name: 'Reset Password',
    component: () => import('../views/ResetPassword.vue'),
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/dashboard/Dashboard.vue'),
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: 'donate',
        name: 'Donate',
        component: () => import('../views/dashboard/views/Donate.vue'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: 'my-account',
        name: 'My Account',
        redirect: { name: 'Details' },
        component: () => import('../views/dashboard/views/MyAccount.vue'),
        beforeEnter: ifAuthenticated,
        children: [
          {
            path: 'details',
            name: 'Details',
            component: () => import('../views/dashboard/views/Detail.vue'),
            beforeEnter: ifAuthenticated,
          },
          {
            path: '2FA',
            name: '2FA',
            component: () => import('../views/dashboard/views/2FA.vue'),
            beforeEnter: ifAuthenticated,
          },
          {
            path: 'my-characters',
            name: 'My Characters',
            component: () => import('../views/dashboard/views/MyCharacters.vue'),
            beforeEnter: ifAuthenticated,
          },
          {
            path: 'create-characters',
            name: 'Create Characters',
            component: () => import('../views/dashboard/views/CreateCharacters.vue'),
            beforeEnter: ifAuthenticated,
          },
          {
            path: 'my-orders',
            name: 'My Orders',
            component: () => import('../views/dashboard/views/MyOrders.vue'),
            beforeEnter: ifAuthenticated,
          },
          {
            path: 'my-tickets',
            name: 'My tickets',
            component: () => import('../views/dashboard/views/MyTickets.vue'),
            beforeEnter: ifAuthenticated,
          },
          {
            path: 'create-ticket',
            name: 'Create Ticket',
            component: () => import('../views/dashboard/views/CreateTicket.vue'),
            beforeEnter: ifAuthenticated,
          },
          {
            path: 'ticket/:id',
            name: 'Ticket',
            component: () => import('../views/dashboard/views/Ticket.vue'),
            beforeEnter: ifAuthenticated,
          },
          {
            path: 'guild/create',
            name: 'Create Guild',
            component: () => import('../views/dashboard/views/CreateGuild.vue'),
          },
          {
            path: 'guild/invitations',
            name: 'Invitations',
            component: () => import('../views/dashboard/views/GuildInvitations.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '*',
    name: 'Error 404',
    component: () => import('../views/404.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = 'Pagodera - OT Server';
  next();
});

export default router;

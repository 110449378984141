import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});
Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [vuexLocal.plugin],
  state: {
    user: {},
    snackBar: {},
    lastUpdate: null,
  },
  getters: {
    getUser(state) {
      return state.user;
    },
    getSnackBar(state) {
      return state.snackBar;
    },
    getLastUpdate(state) {
      return state.lastUpdate;
    },
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setSnackBar(state, payload) {
      state.snackBar = payload;
    },
    setLastUpdate(state) {
      return state.lastUpdate;
    },
  },
  actions: {
    setUser({ commit }, payload) {
      commit('setUser', payload);
    },
    setSnackBar({ commit }, payload) {
      commit('setSnackBar', payload);
    },
    setLastUpdate({ commit }, payload) {
      commit('setLastUpdate', payload);
    },
  },
  modules: {
  },
});

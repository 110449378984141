<template>
  <section id="navbar">
    <div class="content">
      <div class="logo">
        <img src="../assets/images/favicon.png" alt="Pagodera Logo"/>
      </div>
      <div class="menu">
        <div class="menu-list">
          <md-button @click="$router.push({ name: 'Homepage' })">{{ $t('menu.start') }}</md-button>
          <md-button @click="$router.push({ name: 'About' })">{{ $t('menu.about') }}</md-button>
          <md-button @click="$router.push({ name: 'Donate' })">{{ $t('menu.donate') }}</md-button>
          <md-button @click="$router.push({ name: 'Rankings' })">
            {{ $t('menu.rankings') }}
          </md-button>
          <md-button
            style="color: #666; cursor: not-allowed">
            {{ $t('menu.bazaar') }}
          </md-button>
          <md-button @click="$router.push({ name: 'Guilds' })">
            {{ $t('menu.guilds') }}
          </md-button>
          <md-button
            style="color: #666; cursor: not-allowed">
            {{ $t('menu.community') }}
          </md-button>
          <md-button @click="$router.push({ name: 'Download' })">
            {{ $t('menu.download') }}
          </md-button>
        </div>
        <div class="action-buttons">
          <md-button
            v-if="!getUser?.token"
            @click="$router.push({ name: 'Login' })"
            class="login">{{ $t('menu.login') }}</md-button>
          <md-button
            v-else
            @click="$router.push({ name: 'My Account' })"
            class="login">{{ $t('menu.my-account') }}</md-button>
          <md-button
            @click="$router.push({ name: 'Create Account' })"
            class="create-account">{{ $t('menu.create-account') }}</md-button>
        </div>
      </div>
      <div class="mobile-menu">
        <md-button @click="open = !open" class="md-icon-button">
          <md-icon>menu</md-icon>
        </md-button>
      </div>
    </div>
    <div class="menu-slider" v-if="open">
      <div class="menu-list">
        <md-button @click="goTo('Homepage')">{{ $t('menu.start') }}</md-button>
        <md-button @click="goTo('About')">{{ $t('menu.about') }}</md-button>
        <md-button @click="goTo('Donate')">{{ $t('menu.donate') }}</md-button>
        <md-button @click="goTo('Rankings')">
          {{ $t('menu.rankings') }}
        </md-button>
        <md-button
            style="color: #666; cursor: not-allowed">
            {{ $t('menu.bazaar') }}
        </md-button>
        <md-button @click="$router.push({ name: 'Guilds' })">
          {{ $t('menu.guilds') }}
        </md-button>
        <md-button
          style="color: #666; cursor: not-allowed">
          {{ $t('menu.community') }}
        </md-button>
        <md-button @click="goTo('Download')">{{ $t('menu.download') }}</md-button>
      </div>
      <div class="action-buttons">
        <md-button
          v-if="!getUser?.token"
          @click="goTo('Login')"
          class="login">{{ $t('menu.login') }}</md-button>
        <md-button
          v-else
          @click="goTo('My Account')"
          class="login">{{ $t('menu.my-account') }}</md-button>
        <md-button
          @click="goTo('Create Account')"
          class="create-account">{{ $t('menu.create-account') }}</md-button>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      open: false,
    };
  },
  computed: {
    ...mapGetters(['getUser']),
  },
  methods: {
    goTo(to) {
      this.$router.push({ name: to });
      this.open = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../assets/css/variables';

#navbar {
  width: 100%;
  background-color: rgba($color: #000000, $alpha: .8);
  color: #fff;
  display: flex;
  justify-content: center;
  position: sticky;
  z-index: 9;
  top: 0;

  .content {
    width: 80%;
    display: flex;
    align-items: center;
    transition: .3s;
    z-index: 9;

    @media (max-width: 1250px) {
      width: 100%;
      transition: .3s;
    }

    @media (max-width: 1000px) {
      justify-content: space-between;
    }

    .logo {
      padding: 10px;
      img {
        width: 60px;
      }
    }
    .menu {
      display: flex;
      justify-content: space-between;
      width: 100%;

      @media (max-width: 1000px) {
        display: none;
      }

      .menu-list {
        button {
          color: #fff;
        }
      }

      .action-buttons {
        .login {
          background-color: transparent;
          border: 2px solid $green;
          color: $green;
          margin: 6px;
        }
        .create-account {
          background-color: $green;
          color: #fff;
          margin: 6px;
        }
      }
    }

    .mobile-menu {
      display: none;

      @media (max-width: 1000px) {
        display: block;
      }

      button {
        i {
          color: #fff;
        }
      }
    }
  }

  .menu-slider {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0;
    padding: 100px 30px 30px 30px;
    background-color: rgba($color: #000000, $alpha: .8);
    width: 100%;
    height: 100vh;
    justify-content: space-between;
    .menu-list, .action-buttons {
      display: flex;
      flex-direction: column;
    }

    .menu-list {
      button {
        color: #fff;
      }
    }

    .action-buttons {
      .login {
        background-color: transparent;
        border: 1px solid $dark-green;
        color: $dark-green;
        margin: 6px;
      }
      .create-account {
        background-color: $dark-green;
        color: #fff;
        margin: 6px;
      }
    }
  }
}
</style>
